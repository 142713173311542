<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box" id="modal">
        <div class="columns is-mobile">
          <div class="column">
            <p class="title is-5 has-text-dark">
              <span>
                Using this tool
              </span>
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="close">Close</a>
          </div>
        </div>
        <div class="content">
          <p>
            The Multi valuation tool allows you to value vehicles quickly, in
            bulk. This is done by uploading an Excel (.xlsx) spreadsheet to the
            tool, which is then processed and returns your results.
          </p>
          <p class="subtitle is-6 has-text-dark">
            Required fields
          </p>
          <p>
            There are some required fields that your spreadsheet must contain in
            order for a valuation to be produced:<br>A VRM or VIN must be provided.
          </p>
          <div v-for="(field, index) in requiredFields" :key="index">
            <hr class="hr is-marginless" />
            <div class="columns is-vcentered is-marginless">
              <div class="column">{{ field.property }}</div>
              <div class="column is-narrow">
                <div class="tag is-light is-info is-small">
                  {{ field.description }}
                </div>
              </div>
            </div>
          </div>
          <br />
          <p class="subtitle is-6 has-text-grey-light">
            Additional fields
          </p>
          <p>
            There are some additional fields that your spreadsheet can contain
            to customise your request or response further:
          </p>
          <div class="control is-expanded has-icons-right">
            <input
              type="text"
              name="search"
              autocomplete="off"
              placeholder="Start typing to search"
              class="input is-white has-text-right is-small-mobile"
              v-model="search"
            />
            <span class="icon is-small is-right">
              <i class="fal fa-search"></i>
            </span>
          </div>
          <div class="table-container">
            <div v-if="activeFields.length !== 0">
              <div v-for="(field, index) in activeFields" :key="index">
                <hr class="hr is-marginless" />
                <div class="columns is-vcentered is-marginless">
                  <div class="column">{{ field.property }}</div>
                  <div class="column is-narrow">
                    <div class="tag is-light is-warning is-small">
                      {{ field.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TableEmpty v-else text="No fields found" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiFields',
  components: { TableEmpty: () => import('core/components/TableEmpty') },
  data: () => ({
    search: '',
    baseFields: [
      {
        property: 'Date',
        description: 'The past or future date for the valuation - DD/MM/YYYY',
        type: 'input',
        tag: 'valuation'
      },
      {
        property: 'Mileage',
        description: 'The current mileage of the vehicle',
        type: 'input',
        tag: 'valuation'
      },
      {
        property: 'Output Age',
        description: 'The age of the vehicle in days',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Output Mileage',
        description:
          'The current mileage of the vehicle (our estimated if not provided)',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Output Lifecycle',
        description: 'The lifecycle age in days',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Output Make',
        description: 'The current make of this vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'CID',
        description: 'CID for the vehicle',
        type: 'input/output',
        tag: 'valuation'
      },
      {
        property: 'Retail Upper',
        description: 'Produces the upper band of the current retail price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Retail Lower',
        description: 'Produces the lower band of the current retail price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Trade Poor',
        description:
          'Produces retail valuation for a vehicle in poor condition',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Franchise Value',
        description: 'Produces the current franchise price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Independent Value',
        description: 'Produces the current independent price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Supermarket Value',
        description: 'Produces the current supermarket price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Young Used Retail',
        description: 'Produces the current young used price',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'OCN',
        description: 'Original Cost New',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Days to Sale',
        description: 'How long this vehicle takes to sell',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Stocking Depreciation',
        description: 'Shows the amount of depreciation over the days to sale',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Depreciation',
        description: 'Shows the total depreciation compared to its OCN',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Type',
        description: 'The type of vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Model',
        description: 'The model of the vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Derivative',
        description: 'The trim of the vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Manufacturer year',
        description: 'Shows year of manufacture',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Body style',
        description: 'Shows year body style',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Steering position',
        description: 'Shows position of steering wheel',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Transmission',
        description: 'Shows type of gear box',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Doors',
        description: 'Shows number of doors',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Seats',
        description: 'Shows number of seats',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Fuel type',
        description: 'Shows fuel type',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Fuel delivery',
        description: 'Shows fuel delivery system',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Engine capacity',
        description: 'Shows engine capacity in litres',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Engine size CC',
        description: 'Shows engine capacity in CC',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Engine position',
        description: 'Shows engine position',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Valves',
        description: 'Shows number of valves',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Cylinders',
        description: 'Shows number of cylinders',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Cylinder layout',
        description: 'Layout of cylinders',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Gears',
        description: 'Shows number of gears',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Power output',
        description: 'Shows power output in BHP',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Power output KW',
        description: 'Shows power output in KW',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Wheelbase',
        description: 'Shows wheelbase measurements',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Drive axle',
        description: 'Shows the drive axle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Drive type',
        description: 'Shows drivetrain type',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Built from',
        description: 'When this CID started being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Built to',
        description: 'When this CID stopped being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Model version from',
        description: 'When this model started being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Model version to',
        description: 'When this model stopped being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Spec version from',
        description: 'When this spec version started being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Spec version to',
        description: 'When this spec version stopped being produced',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'CO2 emissions',
        description: 'Shows the emissions produced by this vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Colour',
        description: 'Shows colour of the vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Spec description',
        description: 'A description of the spec type',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Standard equipment',
        description: 'A list of the standard fit for this vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Optional equipment',
        description: 'A list of the extra options available on this vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Output first registration',
        description: 'Shows the date of first registration',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Registration date',
        description: 'Registration date to value a CID',
        type: 'input',
        tag: 'spec'
      },
      {
        property: 'Base price',
        description: 'Shows the base price of the vehicle',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'On the road price',
        description: 'The price of the vehicle all costs included',
        type: 'output',
        tag: 'spec'
      },
      {
        property: 'Percent of OCN',
        description: 'The percentage of the OCN for this vehicle',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Gross profit',
        description:
          'The profit to be made on this vehicle (retail value less trade value)',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Gross profit percentage',
        description: 'The percentage profit to be made on this vehicle',
        type: 'output',
        tag: 'valuation'
      },
      {
        property: 'Previous owners',
        description: 'The number of previous owners',
        type: 'output',
        tag: 'keeper'
      },
      {
        property: 'Keeper acquisition',
        description: 'The date on which the current owner acquired the vehicle',
        type: 'output',
        tag: 'keeper'
      },
      {
        property: 'Previous keeper acquisition',
        description:
          'The date on which the previous owner acquired the vehicle',
        type: 'output',
        tag: 'keeper'
      },
      {
        property: 'Previous keeper disposal',
        description:
          'The date on which the previous owner disposed of the vehicle',
        type: 'output',
        tag: 'keeper'
      },
      {
        property: 'MOT date',
        description: 'Most recent MOT test date',
        type: 'output',
        tag: 'mot'
      },
      {
        property: 'MOT status',
        description: 'Most recent MOT test status',
        type: 'output',
        tag: 'mot'
      },
      {
        property: 'MOT mileage',
        description: 'Most recent MOT test mileage',
        type: 'output',
        tag: 'mot'
      },
      {
        property: 'Dealer name',
        description:
          'If on sale, the business name of the dealer stocking this vehicle',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Dealer location',
        description:
          'If on sale, the location of the dealer stocking this vehicle',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'First seen',
        description: 'If on sale, on which this vehicle was listed',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Listing price',
        description: 'If on sale, the current listing price',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Listing original price',
        description:
          'If on sale, the original listing price (if the price has changed)',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Last on sale',
        description: 'The most recent date the vehicle was last on the market',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Is sold',
        description: 'Whether this vehicle is currently on sale',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Sold date',
        description: 'The date of the most recent sale',
        type: 'output',
        tag: 'listing'
      },
      {
        property: 'Ad text',
        description: 'The text from the vehicle listing',
        type: 'output',
        tag: 'listing'
      }
    ],
    requiredFields: [
      {
        property: 'VRM',
        description: "The vehicle's registration number"
      },
      {
        property: 'VIN',
        description: "The vehicle's identification number"
      },
      {
        property: 'Retail',
        description: 'Produces the current retail price'
      },
      {
        property: 'Trade',
        description: 'Produces the current trade price'
      }
    ]
  }),
  methods: {
    close() {
      this.$modal.close()
    }
  },
  computed: {
    activeFields() {
      const { search, baseFields } = this
      if (search === '') return baseFields

      return baseFields.filter(field => {
        return (
          field.property.toLowerCase().includes(search.toLowerCase()) ||
          field.tag.toLowerCase().includes(search.toLowerCase()) ||
          search.toLowerCase().includes(field.tag.toLowerCase())
        )
      })
    }
  }
}
</script>

<style lang="sass" scoped>
#modal
  height: 80vh
  overflow: auto
</style>
